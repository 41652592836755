<template>
	<div />
</template>

<script>
import { LOGOUT, apolloClient } from '@/graphql';
import { onLogout } from '@/graphql/vue-apollo';

export default {
	async created() {
		await onLogout(apolloClient);
		this.$apollo
			.mutate({
				mutation: LOGOUT,
			})
			.finally(() => {
				this.$router.push({ name: 'login' });
			});
	},
};
</script>

<style></style>
